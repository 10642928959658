//@import '_shared.scss';

.home-banner-version-2 {
  .first-screen-inputs {
    justify-content: space-around;
  }

  .order-block-body {
    display: block !important;
  }
}


.home-banner {
  //max-width: 320px;
  margin-right: 0 !important;
  padding: 20px;

  p {
    margin-top: 0;
  }

  img {
    //width: 320px;
    //height: 320px;

    //@media (max-width: 890px) {
    //  width: 250px;
    //  height: 250px;
    //}
  }
}

.home-banner-version-2 {
  .text-block.text-beta-block {
    display: none;
  }
}

.home-banner-version-1 {
  .inputs-wrapper:not(.home-banner) {
    border-right: solid 1px #b1b1b1;
    padding-right: 30px;
  }
}