.user-login input[type="password"] {
  font-family: inherit !important; }

@media (max-width: 768px) {
  .user-login input {
    margin: 16px auto !important; } }

@media (max-width: 768px) {
  .user-login .actions-inline-container {
    display: block; } }

@media (max-width: 768px) {
  .user-login .actions-inline-container > button {
    margin-top: 10px;
    margin-bottom: 15px; } }

@media (max-width: 768px) {
  .user-login .actions-inline-container > a {
    display: block;
    margin: 0 auto 20px auto; } }

.actions-inline-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .actions-inline-container a {
    font-family: Baufra Medium, sans-serif;
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4e8af3; }
  .actions-inline-container #auth-login-button {
    min-width: 166px; }
