@font-face {
  font-family: "Libre Barcode 39";
  src: url('../../../assets/fonts/LibreBarcode39-Regular.ttf')
}
@font-face {
  font-variant-ligatures: discretionary-ligatures;
  font-family: 'ean13';
  src: url('../../../assets/fonts/ean13.woff')
}
.ean13code {
  font-variant-ligatures: discretionary-ligatures;
  font-family: 'ean13';
  font-size:120px;
  margin:5px;
}
div {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
}
.barCode {
  display: block;
  font-size: 1.6rem;
  line-height: 15px;
  padding-top: 15px;
  font-family: "Libre Barcode 39";
  transform: scale(1, 2.5);
  margin-bottom: 10px;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.barCodeInternal {
  display: block;
  font-size: 1.2rem;
  line-height: 15px;
  padding-top: 10px;
  font-family: "Libre Barcode 39";
  transform: scale(1, 2.5);
  margin-bottom: 3px;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.logoWrapper {
  flex: 0 0 50px;
}
.bagImage {
  display: flex;
  align-items: flex-end;
  flex: 0 0 35px;
}
.stationText {
  flex: 1;
}
.printPage {
  max-width: 60mm;
  overflow: hidden;
  padding: 1mm;
}
p {
  margin: 0;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border: 1px solid black;
  padding: 4px;
}
.infoTitle {
  font-weight: bold;
  font-size: 10px;
  margin: 0;
}
.infoSubtitle {
  font-size: 7px;
}
.headerWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  margin-bottom: 8px;
}
.blockWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.blockTitle {
  font-weight: bold;
  font-size: 12px
}
.blockValue {
  font-size: 14px;
}
.addressOrderName {
  font-weight: bold;
  font-size: 16px;
  margin: 3px 0;
}
.printDivider {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  border-bottom: 1px dashed #606060
}
.barCodeWrapper {
  display: flex;
  flex-direction: column;
}
.barCodeTitle {
  font-weight: bold;
  font-size: 13px;
}
.barCodeInfoWrapper {
  display: flex;
  justify-content: space-between;
}
.barCodeInfoText {
  font-weight: bold;
  font-size: 14px;
}
.barCodeInternalInfoText {
  font-size: 12px;
  text-align: center;
}
.barCodeTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.footerText {
  font-size: 10px;
  text-align: center;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
}
.barCodeInternalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.barCodeInternalName {
  font-weight: bold;
  font-size: 15px;
}
.internalCodeWrapper {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  align-items: flex-start;
}
.stationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper {
  background: white;
  border: 1px dashed lightgray;
  padding: 1mm;
}

.inputs-wrapper p {
  margin: 0 !important
}