// todo@alexbuturlakin: This is a fast solution!
//Breakpoints
$desktop: 1200px;
$desktop-m: 1024px;
$tablet: 768px;
$mobile: 425px;
$mobile-s: 320px;

// Media Queries
$mq-desktop: '(max-width: #{$desktop})';
$mq-desktop-m: '(max-width: #{$desktop-m})';
$mq-tablet: '(max-width: #{$tablet})';
$mq-mobile: '(max-width: #{$mobile})';
$mq-mobile-s: '(min-width: #{$mobile-s})';
$mq-retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

.has-bag-select-container {
  width: 30%;
  font-size: 12px;

  @media #{$mq-desktop-m} {
    width: 50%;
  }

  span {
    padding-top: 10px;
    display: block;
  }
}

.home-banner.home-banner-grid {
  @media #{$mq-tablet} {
    min-width: unset !important;
    margin: 0 !important;
    max-width: unset !important;
    width: 100% !important;
  }
}

.has-bag-header {
  p {
    font-size: 15px;
    font-family: Baufra Semi, sans-serif;
  }
}

.has-bag-block .has-bag-info {
  margin-top: 10px !important;
}

.inputs-wrapper.select-code {
  min-width: 600px;
  //
  //@media #{$mq-desktop-m} {
  //  min-width: 420px !important;
  //}

  //@media #{$mq-mobile-s} {
  //  min-width: 300px !important;
  //}

  button {
    margin: 6px auto;
  }
}

#step-5-user-checkbox-accept-terms {
  a {
    color: #4e8af3;
  }

  a:hover {
    color: #4e8af3;
  }
}

.service-point-info {
  //max-width: 370px;
  margin: 15px;
  padding: 15px 10px;
  width: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  & > * {
    width: 100%;
  }

  & > .block-title {
    margin-bottom: 10px;
    font-weight: bold;
  }

  &.service-point-error {
    border: 1px solid #db8f8f;
  }

  &.service-point-show {
    border: 1px solid #979797;
  }

  &.order-contact {
    a {
      margin-right: 5px;
    }
  }

  .service-point-company {
    margin-bottom: 10px;
  }

  .pickup-date {
    text-transform: capitalize;
  }
}

//.order-no-terminal {
//  .select-code {
//    @media #{$mq-mobile} {
//      margin-bottom: 40px !important;
//    }
//  }
//}

.inputs-wrapper-title {
  //max-width: 400px;
  align-content: center;
  justify-content: center;
  display: flex
}

.second-option-block {
  &>h2 {
    margin-bottom: 10px;
  }
  .second-option-mobile .select-date-modal-wrapper {
    z-index: 501;
  }
}

.service-point-info.service-point-actions {
  margin: 0;
  padding-top: 0;
}

.printPage {
  max-width: 60mm;
  overflow: hidden;
  padding: 1mm;
}
