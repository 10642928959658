.overview-total {
  color: #15709d;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: 40px; }
  .overview-total .overview-section {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0; }
  .overview-total h3 {
    color: #003264;
    text-shadow: 1px 0 #003264;
    letter-spacing: 1px;
    font-weight: bold; }
    .overview-total h3 span, .overview-total h3 img {
      vertical-align: text-bottom; }
    .overview-total h3 .img-wrapper {
      width: 55px;
      display: inline-block; }
    @media (max-width: 425px) {
      .overview-total h3 {
        font-size: 1.07em; } }
  .overview-total h4 {
    color: #003264;
    font-size: 1.17em; }
    @media (max-width: 425px) {
      .overview-total h4 {
        font-size: 1.07em; } }
  .overview-total .description {
    display: flex;
    padding-left: 55px; }
    .overview-total .description img {
      height: 30px;
      flex-shrink: 0;
      margin-right: 10px;
      position: relative;
      top: -7px; }
  .overview-total .overview-box {
    border: 2px solid #D7D7D7;
    padding: 0 19px;
    display: inline-block;
    margin-right: 20px;
    max-width: 220px;
    width: 220px;
    margin-bottom: 10px; }
    .overview-total .overview-box.overview-shipping-no-charge {
      border-color: #519A38; }
    .overview-total .overview-box h4 {
      margin-top: 13px;
      margin-bottom: 15px; }
    .overview-total .overview-box p {
      font-weight: normal; }
  .overview-total .overview-pay-later .overview-pay-later-card-icon {
    width: 35px;
    position: relative;
    top: 1px; }
  .overview-total .overview-pay-later .overview-pay-later-info-icon {
    width: 30px; }
  .overview-total .overview-pay-later p image {
    width: 30px; }
  .overview-total .overview-min-order img {
    width: 40px;
    position: relative;
    top: 4px; }
  .overview-total .overview-shipping img {
    width: 45px;
    position: relative;
    top: 8px; }
